<template>
  <v-container>
    <h1>Profile</h1>
    <img :src="$store.state.user.profileImg" class="rounded-lg"/>
  </v-container>
</template>
<script>
import {Fragment} from "vue-fragment";

export default {
  name: "Profile",
  components: {Fragment},
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'Profile',
  },
};
</script>
